exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-oferta-szczecin-tsx": () => import("./../../../src/pages/oferta-szczecin.tsx" /* webpackChunkName: "component---src-pages-oferta-szczecin-tsx" */),
  "component---src-pages-oferta-tsx": () => import("./../../../src/pages/oferta.tsx" /* webpackChunkName: "component---src-pages-oferta-tsx" */),
  "component---src-pages-qr-szczecin-tsx": () => import("./../../../src/pages/qr-szczecin.tsx" /* webpackChunkName: "component---src-pages-qr-szczecin-tsx" */),
  "component---src-pages-qr-tsx": () => import("./../../../src/pages/qr.tsx" /* webpackChunkName: "component---src-pages-qr-tsx" */),
  "component---src-pages-recommendations-tsx": () => import("./../../../src/pages/recommendations.tsx" /* webpackChunkName: "component---src-pages-recommendations-tsx" */),
  "component---src-pages-zalecenia-tsx": () => import("./../../../src/pages/zalecenia.tsx" /* webpackChunkName: "component---src-pages-zalecenia-tsx" */)
}

